import { FallbackProps } from 'react-error-boundary';

import * as s from './error-display.styles';
import { SadPaperpalLogo } from './logo.component';

export const ErrorDisplay: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <s.container>
    <SadPaperpalLogo width={100} height={142} />
    <s.heading>Sorry but I can't do that.</s.heading>
    <s.error>{error?.message}</s.error>
    <s.button onClick={resetErrorBoundary}>try again ...</s.button>
  </s.container>
);
