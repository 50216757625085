import { RawDraftContentState } from 'draft-js';
import { Epic } from 'redux-observable';
import { distinctUntilChanged, filter, map, skipWhile } from 'rxjs/operators';

import { isEmptyAndUnInitialized } from '../../util/object';
import { Actions, ActionTypes, getPayload, RootState } from '../redux-store';

const toBlockStrings = (state: RawDraftContentState) => state.blocks.map((block) => block.text);

const blocksEquals = (current: RawDraftContentState, previous: RawDraftContentState) =>
  JSON.stringify(toBlockStrings(current)) === JSON.stringify(toBlockStrings(previous));

export const editorChangeActions$: Epic<ActionTypes, ActionTypes, RootState> = (actions$, state$) =>
  actions$.pipe(
    filter<ActionTypes, ReturnType<typeof Actions.editorChanged>>(Actions.editorChanged.match),
    map(getPayload),
    skipWhile(isEmptyAndUnInitialized),
    distinctUntilChanged(blocksEquals),
    map(Actions.editorContentChanged),
  );
