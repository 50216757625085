import { ajax } from 'rxjs/ajax';

import { Sentence } from '../../state/sentences.model';

export function putSentences(sessionId: string, sentences: Sentence[]) {
  return ajax.put(
    `/api/v1/live-session/${sessionId}/sentences`,
    sentences.map((sentence) => sentence.text),
    { 'Content-Type': 'application/json' },
  );
}
