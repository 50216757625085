import { ajax } from 'rxjs/ajax';

import { Sentence } from '../../state/sentences.model';

export function getSuggestions(sessionId: string, sentences: Sentence[]) {
  return ajax.post(
    `/api/v1/live-session/${sessionId}/status`,
    sentences.filter((sentence) => sentence.status === 'sent').map((sentence) => sentence.hash),
    { 'Content-Type': 'application/json' },
  );
}
