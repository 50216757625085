import { toOccurrenceHashMap } from '../../../util/hashmap';
import { locationEquals, Sentence, SentenceLocation, Sentences } from '../../sentences.model';

export function mergeEditorChanges(storedSentences: Sentences, changedSentences: Sentences) {
  const updateTs = Date.now();
  const storedSentenceOccurrences = toOccurrenceHashMap(storedSentences.sentences);

  const newState = {
    ...changedSentences,
    lastUpdateTs: storedSentences.lastUpdateTs,
    lastPushableChange: storedSentences.lastPushableChange,
  };

  newState.sentences.forEach((editorSentence, editorSentenceIndex) => {
    if (sentenceIsUnknown(editorSentence.hash, storedSentenceOccurrences)) {
      markSentenceAsNew(editorSentenceIndex, newState, updateTs);
    } else {
      storedSentenceOccurrences[editorSentence.hash].forEach((sentenceOccurrence) => {
        if (sentenceLocationMatched(sentenceOccurrence.location, editorSentence.location)) {
          transferSentenceAttributes(editorSentenceIndex, sentenceOccurrence, newState);
        }
      });

      if (sentenceIsUntouched(editorSentenceIndex, newState)) {
        markSentenceAsNew(editorSentenceIndex, newState, updateTs);
      }
    }
  });

  return newState;
}

const sentenceIsUnknown = (
  hash: string,
  storedSentenceOccurrences: { [hash: string]: Sentence[] },
) => !storedSentenceOccurrences[hash];

const sentenceLocationMatched = (
  storedSentenceOccurrence: SentenceLocation | undefined,
  editorSentenceOccurrence: SentenceLocation | undefined,
) => locationEquals(storedSentenceOccurrence, editorSentenceOccurrence);

const sentenceIsUntouched = (editorSentenceIndex: number, newState: Sentences) =>
  !newState.sentences[editorSentenceIndex].status;

const markSentenceAsNew = (editorSentenceIndex: number, newState: Sentences, updateTs: number) => {
  newState.sentences[editorSentenceIndex].createdTs = updateTs;
  newState.sentences[editorSentenceIndex].status = 'new';
};

const transferSentenceAttributes = (
  editorSentenceIndex: number,
  savedSentence: Sentence,
  newState: Sentences,
) => {
  newState.sentences[editorSentenceIndex].suggestions = savedSentence.suggestions;
  newState.sentences[editorSentenceIndex].createdTs = savedSentence.createdTs;
  newState.sentences[editorSentenceIndex].finishedTs = savedSentence.finishedTs;
  if (savedSentence.status === 'received') {
    newState.sentences[editorSentenceIndex].status = savedSentence.status;
  }
};
