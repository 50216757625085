import React from 'react';
import styled from 'styled-components';

import Spinner from '../components/spinner';

export type ButtonProps = {
  onClick?: (event: React.SyntheticEvent) => void;
  type?: 'reset' | 'submit' | 'button';
  disabled?: boolean;
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ children, loading, disabled, ...props }) => {
  return (
    <StyledButton disabled={disabled || loading} {...props}>
      {loading ? <Spinner size={24} /> : children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  padding: 1rem 1rem;
  border: none;
  width: 100%;
  display: grid;
  place-items: center;
  border-radius: 0.8rem;
  color: #ffffff;
  background: linear-gradient(135deg, #338bff 0%, #0055ff 100%);
  box-shadow: 0 0.4rem 1.6rem -0.4rem rgba(0, 96, 255, 0.5);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  transition: transform 0.2s;
  cursor: pointer;
  font-family: 'IBM Plex Sans';
  &:hover {
    transform: translateY(-2px);
  }
  &:focus {
    outline: none;
  }
  &:active {
    transform: translateY(2px);
  }
  &:disabled {
    cursor: progress;
    opacity: 0.5;
  }
`;
