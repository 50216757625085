export const isEmpty = (o: any, deep = false): boolean => {
  if (o === null || o === undefined) {
    return true;
  }

  if (typeof o !== 'object') {
    return false;
  }

  if (!deep) {
    return Object.values(o).length === 0;
  }

  return Object.values(o).filter((property) => !isEmpty(property)).length === 0;
};

export const isEmptyAndUnInitialized = <T>(o: T): boolean => {
  return isEmpty(o, true);
};
