import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { DocumentProvider } from './context/document-context';
import { ModalProvider } from './context/modal-context';
import { ToasterProvider } from './context/toaster-context';
import { reduxStore } from './state/redux-store';
import { theme } from './styles/default.theme';

const queryClient = new QueryClient();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={reduxStore}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <DocumentProvider>
              <ToasterProvider>
                <StripeElements stripe={stripePromise}>
                  <ModalProvider>{children}</ModalProvider>
                </StripeElements>
              </ToasterProvider>
            </DocumentProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default Providers;
