import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { PayloadSender, State } from 'xstate';

import { fetchSession } from '../api/session-id/get-session-id.api';
import { TContext, TEvent, TState, useDocumentState } from '../state/document.machine';
import { logToApm } from '../util/log-error';

type UploadContextType =
  | { state: State<TContext, TEvent, any, TState>; send: PayloadSender<TEvent> }
  | undefined;

const DocumentContext = React.createContext<UploadContextType>(undefined);

export const DocumentProvider: React.FC = ({ children }) => {
  const [state, send] = useDocumentState();

  const memoValue = React.useMemo(() => ({ state, send }), [state, send]);
  return <DocumentContext.Provider value={memoValue}>{children}</DocumentContext.Provider>;
};

export const useDocument = (options: DropzoneOptions = {}) => {
  const context = React.useContext(DocumentContext);
  if (context === undefined) {
    throw new Error('useDocument must be used within a DocumentProvider');
  }
  const { push } = useHistory();

  const onDropAccepted = React.useCallback(
    async (acceptedFiles) => {
      context.send({ type: 'UPLOAD_INITIATE', document: acceptedFiles[0] });
      try {
        const { liveSessionId: sessionId } = await fetchSession();
        if (!sessionId) {
          throw new Error('unable to fetch sessionId');
        }
        push(`/${sessionId}`);
        context.send({ type: 'UPLOAD_START', sessionId });
      } catch (error) {
        logToApm(error);
      }
    },
    [context, push],
  );
  const dropZone = useDropzone({
    accept: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ],
    onDropAccepted,
    multiple: false,
    noDragEventsBubbling: true,
    ...options,
  });

  const isUploading = ['preUpload', 'uploading'].includes(context.state.value as string);

  return { ...context, dropZone, isUploading };
};
