import { createSlice } from '@reduxjs/toolkit';

const snapshotSlice = createSlice({
  name: 'session',
  initialState: {},
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    snapshotSent() {},
  },
});

export const actions = snapshotSlice.actions;

export type actionTypes = ReturnType<typeof snapshotSlice.actions.snapshotSent>;

export const snapshotReducer = snapshotSlice.reducer;
