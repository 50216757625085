import axios from 'axios';

import { Sentences } from '../../state/sentences.model';
import { createApiThunk } from '../api-helpers';

export const fetchSnapshot = createApiThunk<string, Sentences>('snapshot/get', (sessionId) => {
  const url = `/api/v2/live-session/${sessionId}/editor-snapshot`;

  return axios.get(url);
});
