import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModal } from '../context/modal-context';
import { isDevelopment } from '../util/helpers';
import PaymentModal from './modals/payment-modal';
import TocModal from './modals/toc-modal';

const Panel = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  border-radius: 16px;
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #333;
  width: 180px;
`;

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
`;

const PanelBody = styled.div`
  margin-top: 1rem;
`;

const DevelopmentPanel: React.FC = () => {
  const [show, setShow] = useState(() => localStorage.getItem('devPanel') || 'close');

  const handelDevelopmentPanel = () => {
    setShow((previous) => (previous === 'show' ? 'close' : 'show'));
  };

  useEffect(() => {
    localStorage.setItem('devPanel', show);
  }, [show]);

  const { open } = useModal();

  return isDevelopment ? (
    <Panel>
      <PanelHeader>
        <b>Dev Panel</b>
        <button onClick={handelDevelopmentPanel}>{show === 'show' ? 'close' : 'show'}</button>
      </PanelHeader>
      {show === 'show' && (
        <PanelBody>
          <button onClick={() => open({ children: <PaymentModal /> })}>open Payment modal</button>
          <button onClick={() => open({ children: <TocModal /> })}>open TOC modal</button>
        </PanelBody>
      )}
    </Panel>
  ) : null;
};

export default DevelopmentPanel;
