export type WithHash = { hash: string };

export type HashMap = { [hash: string]: WithHash };

export const toHashMap = <T extends WithHash>(
  input: T[],
  filterFn = (value: T) => true,
): { [hash: string]: T } => {
  const hashMap = {} as { [hash: string]: T };

  input
    .filter((value) => filterFn(value))
    .forEach((entry) => {
      hashMap[entry.hash] = entry;
    });

  return hashMap;
};

export const toOccurrenceHashMap = <T extends WithHash>(input: T[]): { [hash: string]: T[] } => {
  const sentencesMap = {} as { [hash: string]: T[] };

  input?.forEach((entry) => {
    const occurrences = sentencesMap[entry.hash] || [];

    sentencesMap[entry.hash] = [...occurrences, entry];
  });

  return sentencesMap;
};

export const toOccurrenceIndexHashMap = <T extends WithHash>(
  input: T[],
): { [hash: string]: number[] } => {
  const indexHashMap = {} as { [hash: string]: number[] };

  input.forEach((entry, index) => {
    const occurrences = indexHashMap[entry.hash] || [];

    indexHashMap[entry.hash] = [...occurrences, index];
  });

  return indexHashMap;
};
