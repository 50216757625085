/* eslint-disable unicorn/no-nested-ternary */
import { RouteChildrenProps } from 'react-router';
import styled from 'styled-components';

import { CancelIcon } from '../../components/icons/cancel-icon';
import MSWordIcon from '../../components/icons/msword-icon';
import Spinner from '../../components/spinner';
import { useDocument } from '../../context/document-context';
import EditorWorkspace from './editor-workspace';

type Props = RouteChildrenProps<{ sessionId?: string }>;

const MainPage: React.FC<Props> = () => {
  const {
    dropZone: { isDragActive, isDragReject, getRootProps, getInputProps, open },
    state,
    isUploading,
  } = useDocument({ noKeyboard: true, noClick: true });

  if (state.matches('uninitialized')) {
    return null;
  }
  if (isUploading) {
    /**
     * shows when only when we are uploading this is a return to stop dragging
     * and uploading new file while the first one is stilling uploading
     */

    return (
      <DropZone>
        <Spinner size={400} />
        <InnerContent>
          <MSWordIcon height={90} look={'default'} />
          <HeroText>{state.context.document?.name}</HeroText>
          <HelperText>Uploading your document...</HelperText>
        </InnerContent>
      </DropZone>
    );
  }

  /**
   * default to showing upload circle
   */
  let content = (
    <StyledDropZone onClick={open} isDragActive={isDragActive} isDragReject={isDragReject}>
      <input {...getInputProps()} />
      <Content>
        {isDragReject ? (
          <>
            <CancelIcon height={90} color="#CC0044" />
            <HeroText>Unsupported file format</HeroText>
            <UploadButton>Please upload a Microsoft Word file</UploadButton>
          </>
        ) : (
          <>
            <MSWordIcon height={90} look={'default'} />
            <HeroText>Upload your Word file here</HeroText>
            <UploadButton>Click here to upload</UploadButton>
          </>
        )}
      </Content>
    </StyledDropZone>
  );

  /**
   * show editor when preview text is available, and the user is not trying to upload again
   */
  if (state.context.preview && !isDragActive) {
    content = <EditorWorkspace />;
  }

  return <DropZone {...getRootProps()}>{content}</DropZone>;
};

export default MainPage;

const DropZone = styled.div`
  all: inherit;
  position: relative;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

const StyledDropZone = styled.div<{ isDragReject?: boolean; isDragActive?: boolean }>`
  margin: 0px 40px;
  display: grid;
  place-items: center;
  height: 60rem;
  width: 60rem;
  border-width: 0.3rem;
  border-style: dashed;
  border-color: ${({ isDragReject, isDragActive }) => {
    return isDragReject ? '#CC0044' : isDragActive ? '#0060FF' : '#AFB7CA';
  }};
  background-color: ${({ isDragActive }) => (isDragActive ? '#E6F0FF' : '#fffff')};
  border-radius: 50%;
  outline: none;
  transition: all 0.1s ease-in-out;
  &:hover {
    border-color: #0060ff;
    background-color: #e6f0ff;
    cursor: pointer;
  }
`;

const Content = styled.div`
  gap: 1.6rem;
  display: grid;
  place-items: center;
`;

const InnerContent = styled(Content)`
  position: absolute;
`;

const HeroText = styled.h1`
  color: ${({ theme }) => theme.palette.text.main};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: 400;
`;

const UploadButton = styled.button`
  border: none;
  background: none;
  color: blue;
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSize.regular};
  cursor: pointer;
`;

const HelperText = styled.p`
  color: ${({ theme }) => theme.palette.text.light};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;
