import styled from 'styled-components';

type Props = {
  height: string | number | undefined;
  width: string | number | undefined;
};

const SIZE = 32;

export const icon = styled.svg<Props>`
  height: ${(p: Props) => (p.height ? `${p.height}px` : `${SIZE}px`)};
  width: ${(p: Props) => (p.height ? `${p.height}px` : `${SIZE}px`)};
`;
