import styled from 'styled-components';

type Colors = 'default' | 'grey';

const toHex = (color: Colors) => (color === 'default' ? '#FDE98F' : '#EEF3F6');

export const editorSuggestion = styled.span<{ color: Colors }>`
  border-bottom: 1px solid ${(props) => toHex(props.color)};
  box-shadow: 0 -3px 0 ${(props) => toHex(props.color)} inset;
  cursor: pointer;
`;

export const editorSuggestionActive = styled.span<{ color: Colors }>`
  background: ${(props) => toHex(props.color)};
  border-radius: 4px;
`;
