import { Epic, ofType } from 'redux-observable';
import { distinctUntilChanged, map, mergeMap, skipWhile } from 'rxjs/operators';

import { postSnapshot } from '../../api/snapshot/post-snapshot.api';
import { isEmptyAndUnInitialized } from '../../util/object';
import { Actions, ActionTypes, RootState } from '../redux-store';

const payloadEquals = (current: any, previous: any) =>
  JSON.stringify(current) === JSON.stringify(previous);

type TriggerTypes = ReturnType<
  | typeof Actions.sentencesSent
  | typeof Actions.sentencesReceived
  | typeof Actions.rejectSuggestion
  | typeof Actions.acceptSuggestion
>;

export const postSnapshotActions$: Epic<ActionTypes, ActionTypes, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType<ActionTypes, TriggerTypes>(
      Actions.sentencesSent.type,
      Actions.sentencesReceived.type,
      Actions.rejectSuggestion.type,
      Actions.acceptSuggestion.type,
    ),
    skipWhile(isEmptyAndUnInitialized),
    distinctUntilChanged(payloadEquals),
    mergeMap(() => {
      return postSnapshot(state$.value.session.sessionId, state$.value.sentences).pipe(
        map(() => Actions.snapshotSent()),
      );
    }),
  );
