import md5 from 'md5';

import { HubbleSuggestion, Sentences, SuggestionLocation } from '../../sentences.model';

export function removeSuggestion(
  storedSentences: Sentences,
  location: SuggestionLocation,
): Sentences {
  const matches = storedSentences.sentences.filter(
    (sentence) =>
      sentence.location?.blockKey === location.blockKey &&
      sentence.location.sentenceIndex === location.sentenceIndex,
  );

  if (matches.length > 0) {
    const suggestions = [] as HubbleSuggestion[];
    matches[0].suggestions?.forEach((suggestion, index) => {
      if (index !== location.suggestionIndex) {
        suggestions.push(suggestion);
      }
    });
    matches[0].suggestions = suggestions;
  }
  storedSentences.lastUpdateTs = Date.now();
  storedSentences.lastPushableChange = 'reject';

  return storedSentences;
}

export function acceptSuggestion(
  storedSentences: Sentences,
  location: SuggestionLocation,
): Sentences {
  const matches = storedSentences.sentences.filter(
    (sentence) =>
      sentence.location?.blockIndex === location.blockIndex &&
      sentence.location.sentenceIndex === location.sentenceIndex,
  );

  if (matches.length > 0) {
    matches[0].suggestions?.forEach((suggestion, index) => {
      if (index === location.suggestionIndex) {
        const modified = applySuggestion(
          matches[0].text,
          index,
          matches[0].suggestions as HubbleSuggestion[],
        );

        matches[0].text = modified.text;
        matches[0].hash = modified.hash;
        matches[0].suggestions = modified.suggestions as HubbleSuggestion[];
      }
    });
  }
  storedSentences.lastUpdateTs = Date.now();
  storedSentences.lastPushableChange = 'accept';

  return storedSentences;
}

type SuggestionMarker = {
  start: number;
  end: number;
  replacement: string;
};

export function applySuggestion(
  text: string,
  suggestionIndex: number,
  suggestions: SuggestionMarker[],
) {
  const selectedSuggestion = suggestions[suggestionIndex];
  const newText =
    text.slice(0, selectedSuggestion.start) +
    selectedSuggestion.replacement +
    text.slice(selectedSuggestion.end);
  const offsetBy = newText.length - text.length;

  return {
    text: newText,
    hash: md5(newText),
    suggestions: suggestions
      .map((suggestion, index) => {
        if (index === suggestionIndex) {
          return suggestion;
        }

        const start =
          suggestion.start < selectedSuggestion.start
            ? suggestion.start
            : suggestion.start + offsetBy;

        const end =
          suggestion.end < selectedSuggestion.end ? suggestion.end : suggestion.end + offsetBy;

        return {
          ...suggestion,
          start,
          end,
        };
      })
      .filter((suggestion, index) => index !== suggestionIndex),
  };
}
