import { nanoid } from 'nanoid';

import { toHashMap, toOccurrenceHashMap } from '../../../util/hashmap';
import { isEmptyAndUnInitialized } from '../../../util/object';
import { Sentence, Sentences } from '../../sentences.model';

export function mergeReceivedSuggestions(storedSentences: Sentences, receivedSentences: Sentences) {
  if (!storedSentences.sentences || !receivedSentences?.sentences) {
    return storedSentences;
  }

  const sentencesWithNewSuggestions = getSentenceMapWithNewSuggestions(
    storedSentences.sentences,
    receivedSentences.sentences,
  );

  if (Object.keys(sentencesWithNewSuggestions).length === 0) {
    return storedSentences;
  }

  const updateTs = Date.now();

  return {
    sentences: storedSentences.sentences.map((storedSentence) => {
      return isSentenceMatchWithStatus(storedSentence, sentencesWithNewSuggestions, 'sent')
        ? withReceivedSuggestions(
            storedSentence,
            sentencesWithNewSuggestions[storedSentence.hash],
            updateTs,
          )
        : storedSentence;
    }),
    lastUpdateTs: updateTs,
    lastPushableChange: 'hubble',
  } as Sentences;
}

export function markSentencesAsSent(storedSentences: Sentences, sentSentences: Sentences) {
  if (!storedSentences.sentences || !sentSentences?.sentences) {
    return storedSentences;
  }

  const sentSentenceOccurrences = toOccurrenceHashMap(sentSentences.sentences);

  if (Object.keys(sentSentenceOccurrences).length === 0) {
    return storedSentences;
  }

  return {
    lastUpdateTs: storedSentences.lastUpdateTs,
    lastPushableChange: storedSentences.lastPushableChange,
    sentences: storedSentences.sentences.map((storedSentence) => {
      return isSentenceMatchWithStatus(storedSentence, sentSentenceOccurrences, 'new')
        ? withStatusSent(storedSentence)
        : storedSentence;
    }),
  } as Sentences;
}

const getSentenceMapWithNewSuggestions = (
  storedSentences: Sentence[],
  receivedSentences: Sentence[],
) => {
  const storedSentenceOccurrences = toOccurrenceHashMap(storedSentences);

  const hasSuggestions = (storedSentences: Sentence[] | undefined) => {
    if (!storedSentences || storedSentences.length === 0) {
      return false;
    }
    return storedSentences
      .map((storedSentence) => isEmptyAndUnInitialized(storedSentence.suggestions))
      .some((isEmpty) => !isEmpty);
  };

  const hasNewSuggestions = (receivedSentence: Sentence) =>
    !!receivedSentence?.done && !hasSuggestions(storedSentenceOccurrences[receivedSentence.hash]);

  return toHashMap(receivedSentences, hasNewSuggestions);
};

const isSentenceMatchWithStatus = (
  storedSentence: Sentence,
  sentencesWithNewSuggestions: { [hash: string]: any },
  status: string,
) => storedSentence.status === status && !!sentencesWithNewSuggestions[storedSentence.hash];

const withReceivedSuggestions = (
  storedSentence: Sentence,
  receivedSentence: Sentence,
  updateTs: number,
) => {
  return {
    ...storedSentence,
    status: 'received',
    suggestions: receivedSentence.suggestions?.map((suggestion) => ({
      ...suggestion,
      id: nanoid(),
    })),
    finishedTs: updateTs,
  };
};

const withStatusSent = (storedSentence: Sentence) => {
  return {
    ...storedSentence,
    status: 'sent',
  };
};
