import { CardElement, useElements } from '@stripe/react-stripe-js';
import type {
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import { useState, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DevTool } from '@hookform/devtools';

import { useModal } from '../../../hooks/use-modal';
import { COUNTRIES } from '../../../util/countries';
import { isDevelopment } from '../../../util/helpers';
import Button from '../../cta-button';
import Checkbox from '../../fields/checkbox';
import Input from '../../fields/input';
import Select from '../../fields/select';
import TocModal from '../toc-modal';

export type PaymentInputValues = {
  name: string;
  zipCode: string;
  city: string;
  country: string;
  terms: boolean;
  email: string;
  creditCard: string;
};

type Props = {
  onSubmit: (args: { values: PaymentInputValues; cardElement: StripeCardElement }) => void;
  isCapturingPayment: boolean;
  paymentCaptureError?: StripeError;
};

const getCountries = () => Object.entries(COUNTRIES).map(([value, label]) => ({ value, label }));

const PaymentForm: React.FC<Props> = (props) => {
  const elements = useElements();
  const { openModal, Modal } = useModal();
  const [step, setStep] = useState<'personal' | 'payment'>('personal');
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    getValues,
    setError,
    clearErrors,
  } = useForm<PaymentInputValues>(
    isDevelopment
      ? {
          defaultValues: {
            city: 'John town',
            name: 'John doe',
            zipCode: '0000',
            country: 'DK',
            terms: true,
            email: 'john.doe@cactusglobal.com',
          },
        }
      : {},
  );

  // read errors from payment capturing and attach them to the credit card field
  useEffect(() => {
    if (!props.paymentCaptureError) {
      clearErrors('creditCard');
      return;
    }
    setError('creditCard', { type: 'capture', message: props.paymentCaptureError.message });
  }, [props.paymentCaptureError, setError, clearErrors]);

  const onSubmit: SubmitHandler<PaymentInputValues> = async (values) => {
    if (step === 'personal') {
      return setStep('payment');
    }
    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) {
      throw new Error('no stripe card element');
    }
    props.onSubmit({ values, cardElement });
  };

  // default view on form
  let content = (
    <>
      <NameInput
        {...register('name', { required: 'Please enter your name' })}
        errors={errors}
        label="Your name"
      />
      <ZipCodeInput
        {...register('zipCode', { required: 'Please enter your zip' })}
        errors={errors}
        label="Zip code"
      />
      <CityInput
        {...register('city', { required: 'Please enter your city' })}
        errors={errors}
        label="City"
      />
      <CountrySelect
        {...register('country', { required: 'Please enter your country' })}
        errors={errors}
        options={getCountries()}
        label="Country"
      />
      <TermsCheckbox
        {...register('terms', {
          required: 'Please read and accept the terms of condition',
        })}
        errors={errors}
        label={
          <>
            I accept the{' '}
            <LinkButton underline type="button" onClick={openModal}>
              terms and conditions
            </LinkButton>
          </>
        }
      />
    </>
  );

  // view after personal step
  if (step === 'payment') {
    // eslint-disable-next-line no-control-regex
    const emailRegex =
      // eslint-disable-next-line no-control-regex
      /(?:[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;
    content = (
      <>
        <PersonalInfo>
          <span>{getValues('name')}</span>
          <span>
            {getValues('zipCode')} {getValues('city')}
          </span>
          <span>{COUNTRIES[getValues('country')]}</span>
        </PersonalInfo>
        <LinkButton onClick={() => setStep('personal')}>Edit</LinkButton>
        <Separator />
        <EmailInput
          {...register('email', {
            required: 'Please enter your email',
            pattern: {
              value: emailRegex,
              message: 'Invalid email address',
            },
          })}
          errors={errors}
          label="Your email address"
        />
        <Controller
          control={control}
          name="creditCard"
          rules={{
            validate: ((event: StripeCardElementChangeEvent | undefined) => {
              // show Stripe's internal error message if it has one
              if (event?.error?.message) {
                return event.error.message;
              }

              // show required text but only if validating during a submission
              if (isSubmitting && !event?.complete) {
                return 'Please enter your credit card details';
              }
              // credit card is complete during submission
              return true;
            }) as any,
          }}
          render={({ field: { onChange, onBlur } }) => (
            <CCInput
              label="Credit card"
              name="creditCard"
              errors={errors}
              Component={
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        lineHeight: '1.5rem',
                      },

                      invalid: {
                        color: '#cc0044',
                      },
                    },
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              }
            />
          )}
        />
      </>
    );
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {content}
        <CtaButton type="submit" loading={props.isCapturingPayment}>
          {step === 'personal' ? 'Continue' : 'Pay and download now'}
        </CtaButton>
      </Form>
      {isDevelopment && <DevTool control={control} placement="top-left" />}
      <Modal>
        <TocModal />
      </Modal>
    </>
  );
};

export default PaymentForm;

const Form = styled.form`
  display: grid;
  height: 100%;
  width: 100%;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, auto);
  align-items: center;
`;

const NameInput = styled(Input)`
  grid-column: 1/-1;
`;
const ZipCodeInput = styled(Input)`
  grid-column: 1/3;
`;
const CityInput = styled(Input)`
  grid-column: 3/-1;
`;
const CountrySelect = styled(Select)`
  grid-column: 1/-1;
`;
const TermsCheckbox = styled(Checkbox)`
  grid-column: 1/-1;
`;
const EmailInput = styled(Input)`
  grid-column: 1/-1;
`;

const CCInput = styled(Input)`
  grid-column: 1/-1;
`;

const LinkButton = styled.button<{ underline?: boolean }>`
  border: none;
  background: none;
  font-size: inherit;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  padding: 0;
  text-align: end;
`;

const CtaButton = styled(Button)`
  grid-column: 1/-1;
`;

const PersonalInfo = styled.div`
  grid-column: 1/5;
  line-height: 2.4rem;
  > span {
    display: block;
  }
`;

const Separator = styled.div`
  height: 0.2rem;
  background: #d4dae4;
  grid-column: 1/-1;
`;
