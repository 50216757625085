import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RawDraftContentState } from 'draft-js';

import { fetchSnapshot } from '../../api/snapshot/fetch-snapshot.api';
import { sentencesFromEditorState } from '../editor/editor.transformations';
import { RootState } from '../redux-store';
import { Sentence, Sentences, SuggestionLocation } from '../sentences.model';
import { acceptSuggestion, removeSuggestion } from './transformations/accept-reject-suggestions';
import { mergeEditorChanges } from './transformations/editor-sentence-changes';
import {
  markSentencesAsSent,
  mergeReceivedSuggestions,
} from './transformations/send-receive-suggestions';

const sentencesSlice = createSlice({
  name: 'sentences',
  initialState: {} as Sentences,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- RxJS input action
    editorChanged(state, action: PayloadAction<RawDraftContentState>) {},
    editorContentChanged(state, { payload: editorState }: PayloadAction<RawDraftContentState>) {
      // RxJS output action
      return mergeEditorChanges(state, {
        sentences: sentencesFromEditorState(editorState),
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- RxJS input action
    stateChanged(state, action: PayloadAction<Sentences>) {},
    sentencesSent(state, { payload: sentences }: PayloadAction<Sentence[]>) {
      // RxJS output action
      return markSentencesAsSent(state, { sentences });
    },

    sentencesReceived(state, { payload: sentences }: PayloadAction<Sentences>) {
      return mergeReceivedSuggestions(state, sentences);
    },

    acceptSuggestion(state, { payload: location }: PayloadAction<SuggestionLocation>) {
      return acceptSuggestion(state, location);
    },

    rejectSuggestion(state, { payload: location }: PayloadAction<SuggestionLocation>) {
      return removeSuggestion(state, location);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSnapshot.fulfilled, (state, { payload: sentences }) => {
      return {
        sentences: sentences.sentences.map((sentence) => ({
          ...sentence,
          // Ensure that sentences that did not previously receive suggestions are reposted
          status: sentence.status === 'sent' ? 'new' : sentence.status,
        })),
        lastUpdateTs: Date.now(),
        lastPushableChange: 'snapshot',
      };
    });
  },
});

export const SentencesSelector = (state: RootState) => state.sentences;

export const actions = sentencesSlice.actions;

export type actionTypes =
  | ReturnType<typeof sentencesSlice.actions.editorChanged>
  | ReturnType<typeof sentencesSlice.actions.editorContentChanged>
  | ReturnType<typeof sentencesSlice.actions.stateChanged>
  | ReturnType<typeof sentencesSlice.actions.sentencesSent>
  | ReturnType<typeof sentencesSlice.actions.sentencesReceived>
  | ReturnType<typeof sentencesSlice.actions.acceptSuggestion>
  | ReturnType<typeof sentencesSlice.actions.rejectSuggestion>;

export const sentencesReducer = sentencesSlice.reducer;
