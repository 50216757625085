import Draft from 'draft-js';

import { EditorSuggestion } from './editor-suggestion';

export function findEntityRangesByType(entityType: string) {
  return (
    contentBlock: Draft.ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: Draft.ContentState,
  ) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();

      if (entityKey === null) {
        return false;
      }

      const entity = contentState.getEntity(entityKey);

      return entity.getType() === entityType;
    }, callback);
  };
}

export const EditorSuggestionDecorator = new Draft.CompositeDecorator([
  {
    strategy: findEntityRangesByType('EDIT_SUGGESTION'),

    component: (props: { children: any; contentState: Draft.ContentState; entityKey: string }) => {
      const entity = props.contentState.getEntity(props.entityKey).getData();

      const id = entity['id'];
      const category = entity['suppressionCategory'];

      return (
        <EditorSuggestion id={id} category={category}>
          {props.children}
        </EditorSuggestion>
      );
    },
  },
]);
