import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { editorChangeActions$ } from './editor/editor.observables';
import { newSentencesActions$, pollingActions$ } from './sentences/sentences.observables';
import {
  actions as SentenceActions,
  actionTypes as SentencesActionTypes,
  sentencesReducer,
} from './sentences/sentences.slice';
import {
  actions as SessionActions,
  actionTypes as SessionActionTypes,
  sessionReducer,
} from './session/session.slice';
import { postSnapshotActions$ } from './snapshot/snapshot.observable';
import {
  actions as SnapshotActions,
  actionTypes as SnapshotActionTypes,
  snapshotReducer,
} from './snapshot/snapshot.slice';

// These actions are not shown in the redux browser tools
export const actionsBlacklist = [
  'sentences/editorChanged',
  'sentences/stateChanged',
  'session/setSessionIdAndSuggestion',
];

// These are the redux actions and action types
export type ActionTypes = SentencesActionTypes | SessionActionTypes | SnapshotActionTypes;
export const Actions = {
  ...SentenceActions,
  ...SessionActions,
  ...SnapshotActions,
};

// These are the RxJs Epics
export const observables = [
  editorChangeActions$,
  newSentencesActions$,
  pollingActions$,
  postSnapshotActions$,
];

const rootReducer = combineReducers({
  sentences: sentencesReducer,
  session: sessionReducer,
  snapshot: snapshotReducer,
});

export const getPayload = <T>(withPayload: { payload: T }) => withPayload.payload;

export type RootState = ReturnType<typeof rootReducer>;

const reduxObservable = createEpicMiddleware<ActionTypes, ActionTypes, RootState>();

export const reduxStore = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), reduxObservable],
  devTools: {
    actionsBlacklist,
  },
});

reduxObservable.run(combineEpics(...observables));

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./redux-store', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires -- this is a weird cyclical-self-referencing import, but maybe that is needed for hot module replacement
    const newRootReducer = require('./redux-store').reduxStore;
    reduxStore.replaceReducer(newRootReducer);
  });
}
