import { ajax } from 'rxjs/ajax';

import { Sentences } from '../../state/sentences.model';

export function postSnapshot(sessionId: string, snapshot: Sentences) {
  return ajax.post(
    `/api/v2/live-session/${sessionId}/editor-snapshot`,
    {
      sentences: snapshot.sentences,
    },
    { 'Content-Type': 'application/json' },
  );
}
