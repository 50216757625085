import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getFlattenedSuggestions } from '../../../state/sentences.model';
import { SentencesSelector } from '../../../state/sentences/sentences.slice';
import { SessionSelector } from '../../../state/session/session.slice';
import * as s from './editor-suggestion.styles';

type Props = {
  id: string;
  category: string;
};

export const EditorSuggestion: React.FC<Props> = ({ id, children, category }) => {
  const history = useHistory();
  const sentencesState = useSelector(SentencesSelector);
  const sessionsState = useSelector(SessionSelector);
  const elementReference = useRef<HTMLSpanElement>(null);
  const suggestions = getFlattenedSuggestions(sentencesState);
  const selected = Number.parseInt(sessionsState.selectedSuggestion);

  const isSelected = suggestions[selected] ? suggestions[selected].suggestion.id === id : false;
  // Scroll to the suggestion when it is selected
  useEffect(() => {
    if (isSelected) {
      elementReference.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isSelected]);

  const getSuggestionIndex = (newId: string) => {
    for (const [index, suggestion] of suggestions.entries()) {
      if (suggestion.suggestion.id === newId) {
        return index;
      }
    }
    return selected;
  };

  const setSelection = (newId: string) => {
    history.push(`/${sessionsState.sessionId}/${getSuggestionIndex(newId)}`);
  };

  // don't decorate anything if this suggestion is no longer part of the suggestion state
  if (!suggestions.some(({ suggestion }) => suggestion.id === id)) {
    return <>{children}</>;
  }

  const color = category === 'other' ? 'grey' : 'default';

  if (!isSelected) {
    return (
      <s.editorSuggestion
        color={color}
        className={'editorSuggestion'}
        onClick={() => {
          setSelection(id);
        }}
        ref={elementReference}
      >
        {children}
      </s.editorSuggestion>
    );
  }

  return (
    <s.editorSuggestionActive color={color} ref={elementReference}>
      <span>{children}</span>
    </s.editorSuggestionActive>
  );
};
