import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../redux-store';

type SessionInfo = {
  sessionId: string;
  selectedSuggestion: string;
};

const initialState: SessionInfo = {
  sessionId: '',
  selectedSuggestion: '0',
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setSessionIdAndSuggestion(state, { payload: sessionState }: PayloadAction<SessionInfo>) {
      return {
        sessionId: sessionState.sessionId,
        selectedSuggestion: sessionState.selectedSuggestion || '0',
      };
    },
  },
});

export const actions = sessionSlice.actions;

export const SessionSelector = (state: RootState) => state.session;

export type actionTypes = ReturnType<typeof sessionSlice.actions.setSessionIdAndSuggestion>;

export const sessionReducer = sessionSlice.reducer;
