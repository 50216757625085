export type HubbleSuggestion = {
  id?: string;
  category: string;
  replacement: string;
  explanation: string;
  suppressionCategory: string;
  groupNumber: number;
  start: number;
  end: number;
};

export type SentenceLocation = {
  blockIndex: number;
  blockKey: string;
  sentenceIndex: number;
};

export type Sentence = {
  text: string;
  hash: string;
  done?: boolean;
  location?: SentenceLocation;
  createdTs?: number;
  finishedTs?: number;
  status?: 'new' | 'sent' | 'received' | 'applied';
  suggestions?: HubbleSuggestion[];
};

export type Sentences = {
  lastUpdateTs?: number;
  lastPushableChange?: 'accept' | 'reject' | 'hubble' | 'snapshot' | 'reset';
  sentences: Sentence[];
};

export type SuggestionLocation = {
  blockIndex: number;
  blockKey: string;
  sentenceIndex: number;
  suggestionIndex: number;
};

export const getFlattenedSuggestions = (sentenceState: Sentences) =>
  (sentenceState.sentences || []).map((sentence) => mapToSentenceSuggestion(sentence)).flat(1);

const mapToSentenceSuggestion = (sentence: Sentence) => {
  return (
    sentence.suggestions?.map((suggestion, index) => {
      return {
        sentence: {
          text: sentence.text,
          hash: sentence.hash,
        },
        suggestion,
        location: {
          blockIndex: sentence.location ? sentence.location.blockIndex : -1,
          blockKey: sentence.location ? sentence.location.blockKey : '',
          sentenceIndex: sentence.location ? sentence.location.sentenceIndex : -1,
          suggestionIndex: index,
        },
      };
    }) || []
  );
};

export function locationEquals(l1: SentenceLocation | undefined, l2: SentenceLocation | undefined) {
  return l1 && l2 && JSON.stringify(l1) === JSON.stringify(l2);
}
