import { Epic } from 'redux-observable';
import { interval, of } from 'rxjs';
import {
  concatMap,
  debounce,
  distinct,
  filter,
  map,
  mergeMap,
  skipWhile,
  takeWhile,
} from 'rxjs/operators';

import { putSentences } from '../../api/sentences/put-sentences.api';
import { getSuggestions } from '../../api/suggestions/get-suggestions.api';
import { isEmptyAndUnInitialized } from '../../util/object';
import { Actions, ActionTypes, getPayload, RootState } from '../redux-store';
import { Sentences } from '../sentences.model';

const hasSentencesWithStatus = (sentences: Sentences, status: 'new' | 'sent') =>
  sentences.sentences
    .map((sentence) => sentence.status === status)
    .some((statusIsNew) => statusIsNew);

export const newSentencesActions$: Epic<ActionTypes, ActionTypes, RootState> = (actions$, state$) =>
  actions$.pipe(
    filter<ActionTypes, ReturnType<typeof Actions.stateChanged>>(Actions.stateChanged.match),
    map(getPayload),
    skipWhile(isEmptyAndUnInitialized),
    filter(
      (sentences) =>
        sentences.sentences.map((sentence) => sentence.status).filter((status) => status === 'new')
          .length > 0,
    ),
    distinct(),
    debounce(() => interval(300)),
    map((sentencesState) =>
      sentencesState.sentences.filter((sentence) => sentence.status === 'new'),
    ),
    mergeMap((sentences) => {
      return putSentences(state$.value.session.sessionId, sentences).pipe(
        concatMap((response) => {
          return of(Actions.sentencesSent(sentences));
        }),
      );
    }),
  );

export const pollingActions$: Epic<ActionTypes, ActionTypes, RootState> = (actions$, state$) =>
  actions$.pipe(
    filter<ActionTypes, ReturnType<typeof Actions.sentencesSent>>(Actions.sentencesSent.match),
    mergeMap((action) =>
      interval(1000).pipe(
        takeWhile(() => hasSentencesWithStatus(state$.value.sentences, 'sent')),
        mergeMap((sentences) => {
          return getSuggestions(
            state$.value.session.sessionId,
            state$.value.sentences.sentences,
          ).pipe(
            distinct(),
            map((response) => Actions.sentencesReceived(response.response.data)),
          );
        }),
      ),
    ),
  );
