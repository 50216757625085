import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export type ApiError = {
  message: string;
};

export enum ApiStatus {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export const { ERROR, INITIAL, DONE, LOADING } = ApiStatus;

export type ApiSliceState = {
  status: ApiStatus;
  error: string | undefined;
  data: any;
};

export const initialApiState = {
  error: undefined,
  status: INITIAL,
  data: undefined,
} as ApiSliceState;

export function createApiSlice<Returned, ThunkArgument>(
  name: string,
  api: AsyncThunk<Returned, ThunkArgument, { rejectValue: ApiError }>,
) {
  return createSlice({
    name: name,
    initialState: initialApiState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(api.pending, (state) => {
        state.status = LOADING;
        state.error = undefined;
      });
      builder.addCase(api.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = DONE;
      });
      builder.addCase(api.rejected, (state, { payload }) => {
        if (payload) {
          state.error = payload.message;
        }
        state.status = ERROR;
      });
    },
  });
}

export function createApiThunk<TReceives, TReturns>(
  name: string,
  callbackFn: (arguments_: TReceives) => Promise<AxiosResponse<TReturns>>,
) {
  return createAsyncThunk<TReturns, TReceives, { rejectValue: ApiError }>(
    name,
    async (arguments_, thunkApi) => {
      try {
        const response = await callbackFn(arguments_);

        if (response.status !== 200) {
          return thunkApi.rejectWithValue({
            message: `Thunk error: (${response.status} ${response.statusText})`,
          });
        }

        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue({
          message: `Thunk error: (${error})`,
        });
      }
    },
  );
}
