import styled from 'styled-components';

export const container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
`;

export const error = styled.div`
  color: red;
`;

export const button = styled.button`
  margin-top: 24px;
  background: inherit;
  border: 0;
  text-decoration: underline;
  color: inherit;
  font-family: inherit;
`;

export const heading = styled.h2``;
