// Copyright (C) 2020 UNSILO. All Rights Reserved.

import { height, svgPathData, width } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { SVGAttributes } from 'react';

import * as s from './arrow-icon.styles';

type Props = { color?: string } & SVGAttributes<SVGElement>;

export const CancelIcon: React.FC<Props> = ({ color, ...props }) => (
  <s.icon
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    {...props}
    style={{ fill: color || '#334563' }}
  >
    <path d={svgPathData} />
  </s.icon>
);
