import * as cldrSegmentation from 'cldr-segmentation';
import { convertFromRaw, EditorState, RawDraftContentState } from 'draft-js';
import md5 from 'md5';

import { DraftHelper } from '../../util/draftjs-helper';
import { Sentence, Sentences } from '../sentences.model';
import { mapSentencesToDraftJs } from '../sentences/transformations/sentences-to-draftjs.mapper';

export function sentencesFromEditorState(raw: RawDraftContentState) {
  const sentences = [] as Sentence[];

  raw.blocks.forEach((block, blockIndex) => {
    const blockKey = block.key;
    const segmentedText = cldrSegmentation.sentenceSplit(
      block.text,
      cldrSegmentation.suppressions.en,
    );

    let sentenceBegin = 0;
    segmentedText.forEach((text, sentenceIndex) => {
      const hash = md5(text);
      const location = {
        blockIndex,
        blockKey,
        sentenceIndex,
        start: sentenceBegin,
        end: sentenceBegin + text.length,
      };
      const createdTs = Date.now();
      const status = 'new';

      sentences.push({
        text,
        hash,
        location,
        createdTs,
        status,
      });

      sentenceBegin = location.end;
    });
  });

  return sentences;
}

export function editorStateFromSentences(editorState: EditorState, reduxState: Sentences) {
  const editorSelection = editorState.getSelection();
  const stateFromSentences = convertFromRaw(
    mapSentencesToDraftJs(reduxState, DraftHelper.rawEditorState(editorState)),
  );

  const rawEditorState = DraftHelper.rawEditorState(editorState);
  if (rawEditorState.blocks.length === 1 && rawEditorState.blocks[0].text.length === 0) {
    return DraftHelper.createEditorState(stateFromSentences);
  }

  const newEditorState = DraftHelper.pushState(
    editorState,
    stateFromSentences,
    reduxState.lastPushableChange === 'hubble',
  );

  return EditorState.forceSelection(newEditorState, editorSelection);
}
